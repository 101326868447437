@import "mixins"

input:not([class^="jodit"]):not([class^="Mui"]),
button:not([class^="jodit"]):not([class^="new"]):not([class^="Mui"]):not([class^="sign"]),
// label:not([class^="jodit"]),
select:not([class^="jodit"])
  display: block
  width: 100%
  //min-width: 180px
  height: 50px
  appearance: none

  font-style: normal
  font-weight: 600
  font-size: 17px
  line-height: 27px
  border: none
  padding: 0 15px
  border-radius: 6px
  transition: box-shadow .21s, opacity .21s

  &._wa
    width: auto
    min-width: 0
    flex-shrink: 0
    flex: none !important

  &._rounded
    border-radius: 25px

  &._create_table
    padding: 5px 5px 5px 5px
    min-width: 20px
    border-width: 0px

  &._fill_table
    min-width: 40px
    height: 30px
    padding: 0px
    border: 0px !important

input[type="file"]
  display: none

textarea
  padding: 11px 11px
  resize: vertical
  resize: none

button:not([class^="jodit"]):not([class^="Mui"])
  &:disabled
    background: #aaa
    cursor: not-allowed

  &._secondary
    background: #fff
    border: 2px solid $primary-color
    color: $primary-color
    box-shadow: none

  &._max_width
    width: 100%

  &._transparent

    font-style: normal
    font-weight: 600
    font-size: 17px
    line-height: 27px

    color: #0E1F4D

    background: transparent
    box-shadow: none

    &:hover
      color: $hover-color

  &._iconed
    display: flex
    justify-content: center
    align-items: center
    gap: 0 15px

    svg
      width: 24px
      fill: #fff
      flex-shrink: 0

      @at-root #{selector-replace(&, 'button', 'button._secondary._iconed')}
        fill: $primary-color

      @at-root #{selector-replace(&, 'button', 'button._transparent._iconed')}
        fill: $darkest-color !important

      @at-root #{selector-replace(&, 'button', 'button._transparent._iconed:hover')}
        fill: $hover-color

  &.cursor
    cursor: initial

  &.cursor
    cursor: initial

    span
      display: block

  &._zeroed
    width: auto
    min-width: 0
    height: auto
    padding: 0
    border-radius: 0
    background: transparent
    color: $primary-color
    box-shadow: none

    &:disabled
      color: #aaa

    svg
      fill: #000

form
  margin-top: 30px

.small-hint
  font-size: 12pt
  text-align: center
  margin-top: 20px
  color: #0E1F4D

  a
    font-size: 14pt
    font-weight: bold

.form-field
  margin: 10px 0
  gap: 10px

  &._iconed
    position: relative

    & > svg
      position: absolute
      right: 15px
      width: 18px
      top: 50%
      fill: #8E94B1
      transform: translateY(-50%)
      pointer-events: none

  .input-limit
    max-height: 22px
    overflow: hidden

  &._flexed
    display: flex
    gap: 10px
    flex-wrap: wrap

    input,
    button,
    label,
    select
      width: auto
      flex: 1
      border-radius: 10px
      border: 1px solid rgba(142, 148, 177, 0.5)

      @media(max-width: 760px)
        &._wa
          min-width: 150px
          flex: 1 !important
