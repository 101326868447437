$primary-color: #2339AA
$hover-color: #3333A9
$darkest-color: #0E1F4D
$lightest-color: #E9ECF7

@mixin clear-margins

  &:first-child
    margin-top: 0
    margin-left: 0

  &:last-child
    margin-bottom: 0
    margin-right: 0
