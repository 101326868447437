$Blue2: #75809E

.H2_26
  font-style: normal
  font-weight: 700
  font-size: 26px
  line-height: 35px
  color: #121212

.H3_21
  font-style: normal
  font-weight: 700
  font-size: 21px
  line-height: 29px
  color: #0E1F4D

.Subheader_17
  font-style: normal
  font-weight: 600
  font-size: 17px
  line-height: 27px
  color: #0E1F4D

.Subheader_15
  font-style: normal
  font-weight: 600
  font-size: 15px
  line-height: 22px
  color: #121212

.Subheader_13
  font-style: normal
  font-weight: 500
  font-size: 13px
  line-height: 20px
  color: #121212

.Body_17
  font-style: normal
  font-weight: 400
  font-size: 17px
  line-height: 27px
  color: #0E1F4D

.Body_15
  font-style: normal
  font-weight: 400
  font-size: 15px
  line-height: 22px
  color: $Blue2

  &.comment
    color: $Blue2

.Body_13
  font-style: normal
  font-weight: 400
  font-size: 13px
  line-height: 20px
  color: #121212

  &.comment
    color: $Blue2

.ChipsContainer
  display: flex
  flex-wrap: wrap
  flex-direction: row
  align-items: flex-start

  gap: 10px

  .Chips
    height: 24px
    padding: 2px 10px

    background: #EAECF3
    border-radius: 40px

    flex: none
    order: 1
    flex-grow: 0

.empty-list
  display: flex
  flex-direction: column
  align-items: center
  padding: 100px 0
  text-align: center

.qr-code
  display: flex
  margin-top: 30px
  alignment: center

  svg
    margin: auto

.security-info-button
  background: none
  border: none
  color: $Blue2
  font-size: 14px
  text-decoration: underline
  cursor: pointer

.security-info-popup
  padding: 16px
  max-width: 500px

  .security-section
    margin-bottom: 24px

    &:last-child
      margin-bottom: 0

    h4
      margin-bottom: 12px

    p
      margin-bottom: 12px
      color: #333
      line-height: 1.5

  .link-button
    display: inline-block
    padding: 8px 16px
    background: #EAECF3
    border-radius: 6px
    color: #0E1F4D
    text-decoration: none
    font-size: 14px
    
    &:hover
      background: #D4D8E1