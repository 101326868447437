@import "mixins"
@import "inputs"

*
  box-sizing: border-box
  outline: 0
  -webkit-tap-highlight-color: transparent

.center
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  gap: 5px

.flex-container
  display: flex
  align-items: center

  & > *
    margin: 0

  &.__sb
    justify-content: space-between

.hint
  color: #0E1F4D

.tip
  font-style: italic
  color: #0E1F4D
  font-size: 11pt

.__facebook
  fill: #3b5998
  color: #3b5998

.__telegram
  fill: #0088cc
  color: #0088cc

.__vk
  fill: #45668e
  color: #45668e

.__twitter
  fill: #1da1f2
  color: #1da1f2

.__whatsapp
  fill: #075e54
  color: #075e54

#root
  background: #F9F9FB
  width: 100%
  height: 100%
  margin: 0
  padding: 0

html, body
  font-family: 'Nunito Sans'
  width: 100%
  height: 100%
  margin: 0
  padding: 0

button
  font-family: 'Nunito Sans'

body
  font-style: normal
  font-weight: 400
  font-size: 14px
  user-select: text
  overflow-x: hidden

  h1, h2, h3, h4, h5, h6
    margin: 0
    @include clear-margins

  h1, h2, h3
    margin-bottom: 30px

  h4, h5, h6
    margin-top: 30px
    font-weight: 500

  h4
    font-size: 14pt

  p
    @include clear-margins

  hr
    width: 100%
    height: 1px
    margin: 0
    margin: 30px auto
    background: #f1f1f1
    border: none

  img, audio, iframe
    display: block
    width: 100%
    max-width: 100%
    border-radius: 10px

    video
      height: 300px

  a
    text-decoration: none
    font-style: normal
    font-weight: 400
    font-size: 15px
    line-height: 22px
    color: #0E1F4D

    &:hover
      text-decoration: underline

  ul
    padding: 0
    margin: 30px 0
    padding-left: 15px
    @include clear-margins

    li
      margin: 10px 0
      @include clear-margins

/* Root element */

#root
  display: flex
  flex-direction: column

  /* Header */

  header
    display: flex
    flex-shrink: 0
    align-items: center
    justify-content: space-between
    width: 100%
    height: 70px
    padding: 0 50px
    background: white
    $header: &

    @media(max-width: 760px)
      display: none
      padding: 0 30px

    &._fixed
      position: fixed
      left: 0
      right: 0
      z-index: 1

    .header-logo
      height: 40px

    nav
      display: flex
      gap: 5px

      @media(max-width: 760px)
        display: none

      button
        @at-root #{selector-replace(&, 'header', 'header._highlighted')}
          color: #fff

          &.nav-profile-button

            &:hover
              background: $primary-color

            svg
              fill: #fff

      a
        display: blocks

        &:hover
          text-decoration: none

        &.nav-contacts-link

          button
            background: $lightest-color
            color: $hover-color

            @at-root #{selector-replace(&, 'a', 'a#header-contacts-link')}
              background: #fff

    /* === Nav switcher === */

    button.nav-switcher
      display: none

      @media(max-width: 860px)
        display: block

      svg
        width: 35px

        @at-root #{selector-replace(&, 'header', 'header._highlighted')}
          fill: #fff

  /* Mobile nav */
  .mobile-nav
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba($primary-color, .8)
    z-index: 100
    opacity: 0
    visibility: hidden
    transition: .21s

    $a: &

    @media(max-width: 800px)

      &._shown
        visibility: visible
        opacity: 1

    nav
      position: absolute
      display: flex
      flex-direction: column
      gap: 10px
      left: 0
      bottom: 0
      width: 100%
      background: #fff
      padding: 30px
      transform: translateY(100%)
      transition: .32s cubic-bezier(0.34, 1.56, 0.64, 1)
      box-shadow: 0 -3px 25px rgba(0, 0, 0, .2)
      border-radius: 25px 25px 0 0

      &::after
        content: ''
        position: absolute
        top: 100%
        left: 0
        width: 100%
        height: 100%
        background: #fff

      @at-root #{$a}._shown nav
        transform: translateY(0)

      button
        text-align: left

      a
        display: block
        color: #000
        text-decoration: none

        &:hover
          text-decoration: none

        &.nav-contacts-link

          button
            background: $lightest-color
            color: $hover-color

            @at-root #{selector-replace(&, 'a', 'a#header-contacts-link')}
              background: #fff

  /* Pages */

  *[class$="Page"]:not(.mainPage):not(.react-pdf__Page)
    max-width: 1090px
    margin: 16px auto
    padding-left: 16px
    padding-right: 16px

  /* Footer */

  footer
    display: flex
    align-items: center
    flex-wrap: wrap
    justify-content: space-between
    gap: 20px
    flex-shrink: 0
    margin: auto
    margin-bottom: 0
    width: calc(100% - 30px - 30px)
    border-top: 1px solid #eee
    font-size: 10pt
    padding: 15px 0

    @media(max-width: 760px)
      flex-direction: column

    img.footer-logo
      width: auto
      height: 25px

    .social-links
      display: flex
      align-items: center
      gap: 10px

      & > span
        display: block

        @media(max-width: 760px)
          display: none

      a
        display: block

        svg
          width: 30px

    .__left,
    .__right
      display: flex
      align-items: center
      gap: 30px
      flex-shrink: 0

  .footer
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center

    border-radius: 10px 10px 0 0
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.04)
    margin-top: auto
    position: fixed

    background: #fff

    flex-wrap: nowrap

    height: 83px
    width: 100%
    bottom: 0
    gap: 0

    padding-top: 0px
    padding-left: 12px
    padding-right: 12px

    z-index: 2

    svg
      fill: #0E1F4D


    & > div 
      width: 100%
      height: 100%
      font-size: 17px
      display: flex
      flex-direction: column
      flex-wrap: nowrap
      
      justify-content: center
      align-content: center

    & > div > a
      color: #0E1F4D
      outline: none
      width: 100%
      height: 100%

      box-sizing: border-box

      padding: 5px
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center

    & > div > a > p
      width: 100%

      font-style: normal
      font-weight: 600
      font-size: 13px
      line-height: 20px
      text-align: center
      padding: 0
      margin: 0
      @media(max-width: 515px)
        font-size: 9pt
      @media(max-width: 409px)
        font-size: 8pt


    & > div > a > svg
      width: 100%
      margin: 0
      padding: 0

    & > div:nth-child(-n+3) > a > svg
      width: 100%
      transform: scale(0.90)


    & > div > a:hover
      text-decoration: none
    
    .active
      transition: .32s
      color: #1FA3EE
      svg
        fill: #1FA3EE

    



  /* Notification */
  .notification
    position: fixed
    z-index: 10000
    width: calc(100% - 30px - 30px)
    max-width: 300px
    background: #fff
    border-radius: 10px
    box-shadow: 0 0 30px rgba(#222, .3)
    padding: 15px
    opacity: 1
    visibility: visible
    transition: 0.5s
    tranform: translateY(0)

    &._hidden
      opacity: 0
      visibility: hidden
      transform: translateY(-100px)

    .title
      font-size: 14pt
      font-weight: bold

    .content
      margin-top: 10px
      white-space: pre-wrap


    @media(max-width: 760px)
      top: 20px
      margin-left: auto
      margin-right: auto
      left: 0
      right: 0

    @media(min-width: 760px)
      right: 30px
      bottom: 30px

.ql-clipboard
  height: 100%
  width: 100%
  position: absolute
  left: 0
  top: 0
  z-index: -1

.__jivoMobileButton
  bottom: 83px !important

main
  background: #F9F9FB
  @media(max-width: 760px)
    padding-bottom: 140px


.centeredLoader
  display: flex
  justify-content: center
  align-items: center
