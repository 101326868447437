.editContractConstructor
  &.readOnly
    div.jodit-workplace
      border-radius: 20px !important

  margin-top: 20px
  display: flex
  user-select: text

  div.jodit-container
    border: 1px solid #D4D8E1
    border-radius: 20px

  div.jodit-toolbar__box
    background-color: #F2F2F5 !important
    border-top-right-radius: 20px
    border-top-left-radius: 20px

    @media(max-width: 760px)
      top: 133px

  div.jodit-workplace
    border-bottom-right-radius: 20px
    border-bottom-left-radius: 20px

  div.jodit-status-bar
    display: none

  div.jodit-wysiwyg
    word-break: break-word

  @media(max-width: 760px)
    flex-direction: column
    box-shadow: none
    border-radius: 0
    flex-direction: column-reverse

  section
    width: 100%

    @media(max-width: 760px)
      margin-top: 15px

  aside
    flex-shrink: 0
    @media(max-width: 760px)
      width: 100%

    @media(min-width: 760px)
      width: 400px
      margin-left: 15px

.editContractFooterContainer
  margin-top: 20px
  border: 1px solid #D4D8E1
  border-radius: 20px

.editContractFooterTitleContainer
  padding: 20px
  background-color: #F2F2F5
  border-top-right-radius: 20px
  border-top-left-radius: 20px
  border-bottom: 1px solid #D4D8E1

  font-style: normal
  font-weight: 400
  font-size: 17px
  line-height: 27px
  color: #0E1F4D

.editContractFooterTipContainer
  padding: 20px
  background-color: #F2F2F5
  border-bottom-right-radius: 20px
  border-bottom-left-radius: 20px
  border-bottom: 1px solid #D4D8E1

  font-style: normal
  font-weight: 400
  font-size: 17px
  line-height: 27px
  color: #0E1F4D

.editContractFooterBodyContainer
  padding: 20px
  background: white