@import "styles/mixins"

.contract-editor

  user-select: text

  max-width: 650px
    // width: 650px 
    
  @media(max-width: 1090px)
    max-width: calc(100vw - 505px)
    // width: calc(100vw - 500px)

  @media(max-width: 760px)
    max-width: 100%
    // width: 100%

  & > div
    max-width: 650px
    // width: 650px

    @media(max-width: 1090px)
      max-width: calc(100vw - 395px)

    @media(max-width: 760px)
      max-width: 100%
      // width: 100%

.contract-readonly
  user-select: text

  width: 650px
  max-width: 650px

.popup
  display: flex
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  overflow: auto
  z-index: 10
  padding: 100px 30px
  background: rgba(#BDBDBD, .8)
  perspective: 100vw
  animation: fade-in .32s 1 ease-in-out forwards
  transition: .32s
  $popup: &

  @media(max-width: 768px)
    display: block
    padding: 0

  @keyframes fade-in
    0%
      opacity: 0
    100%
      opacity: 1

  &._isClosing
    background: transparent

  .tapAreaBlocker
    padding: 0 30px
    margin: auto

  .wrapper
    position: relative
    width: 100%
    max-width: 500px
    background: #fff
    margin: auto
    border-radius: 30px
    box-shadow: 0px 32px 48px rgba(19, 19, 19, 0.2)
    transform-style: preserve-3d
    animation: fade-in .32s 1 ease-in-out forwards
    transition: .32s

    @media(max-width: 768px)
      max-width: unset
      border-radius: 0
      height: 100%
      overflow: hidden

    @at-root #{$popup}._isClosing .wrapper
      opacity: 0

    @keyframes show-up
      0%
        transform: rotateX(-30deg)

      100%
        transform: rotateX(0)

    // Close button

    .close-button
      margin-top: 20px
      margin-right: 20px
      float: right

    // Popup children

    .popupContainer
      padding: 0px 60px 30px 60px

      @media(max-width: 768px)
        height: calc(100% - 60px)
        overflow: auto
        padding: 30px

      .popup-title

        font-style: normal
        font-weight: 700
        font-size: 21px
        line-height: 29px
        text-align: center
        color: #0E1F4D

    .container.center 
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      
      & > div
        width: 100%

    .confirmation
      display: flex
      width: 100%
      flex-direction: column
      justify-content: center
      align-content: center

      margin-top: 12px
      margin-bottom: 22px

  .promoCodeBonus

    div
      display: flex
      flex-direction: column
      padding: 10px


.tableDeleteButton
  svg
    width: 20px !important
    fill: #F44336 !important

._table_directive
  margin-bottom: 10px
  width: 100%
  border: 1px solid rgba(142, 148, 177, 0.5)
  border-collapse: collapse

._td_directive, ._th_directive
  width: auto
  padding: 5px !important
  border: 1px solid rgba(142, 148, 177, 0.5) !important
  border-collapse: collapse

._table_title_label
  height: auto !important
  margin: 0px !important
  font-weight: bold

._table_label
  height: auto !important
  margin: 0px !important
  font-weight: normal
  border-radius: 0px !important
