@import "mixins"


.classicStyleMuiSelect
  input,
  button,
  label,
  select,
  textarea
    border: none !important
    background: none
    box-shadow: none
    padding: 0px
    height: auto
    width: auto

    font-style: normal
    font-weight: 400
    font-size: 15px
    line-height: 20px

  input
    width: 100%

  button
    color: rgba(0, 0, 0, 0.54) !important

    svg
      margin-top: 2px

  fieldset
    border-color: rgba(142, 148, 177, 0.5)
    border-radius: 10px
