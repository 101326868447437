
.templateViewContainer
  background: #FFFFFF
  border: 1px solid #F2F2F2
  box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.05)
  border-radius: 15px
  padding: 20px
  overflow: hidden

  &.marginTop
    margin-top: 15px

  &:not(.disabledHover):hover
    background: #F9F9FB

  .title
    font-style: normal
    font-weight: 700
    font-size: 17px
    line-height: 27px
    color: #0E1F4D

  .subtitle
    font-style: normal
    font-weight: 400
    font-size: 15px
    line-height: 22px
    color: #0E1F4D

  .b2bLabel
    background: #EAECF3
    border-radius: 40px
    color: white
    padding: 2px 10px 2px 10px
    font-style: normal
    font-weight: 400
    font-size: 15px
    line-height: 22px
    color: #75809E

div
  &.templateTitleContainer
    display: flex
    align-items: flex-start
    justify-content: space-between
    gap: 10px

div.MuiPaper-root
  background: #FFFFFF
  border: 1px solid #F2F2F2
  box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.05)
  border-radius: 15px

li.MuiMenuItem-root

  font-style: normal
  font-weight: 400
  font-size: 15px
  line-height: 22px
  color: #0E1F4D

fieldset.MuiOutlinedInput-notchedOutline
  border: none
  border-radius: 10px

div.MuiListItemText-root
  //font-family: 'Nunito Sans' !important
  font-style: normal !important
  font-weight: 400 !important
  font-size: 15px !important
  line-height: 20px !important
  color: #75809E !important

div.MuiTypography-root
  //font-family: 'Nunito Sans' !important
  font-style: normal !important
  font-weight: 400 !important
  font-size: 15px !important
  line-height: 20px !important
  color: #75809E !important

.video-responsive
  margin-top: 15px

div.contractList
  display: flex
  flex-direction: column
  gap: 15px

label.MuiFormControlLabel-root
  font-style: normal
  font-weight: 400
  font-size: 13px
  line-height: 22px
  color: #75809E

div.MuiInputBase-root
  //font-family: 'Nunito Sans'
  font-style: normal
  font-weight: 400
  font-size: 16px
  line-height: 20px
  //background: #EAECF3
  color: #0E1F4D

div.MuiFormControl-root
  border-radius: 10px
  font-style: normal
  font-weight: 400
  font-size: 15px
  line-height: 20px

  &.MuiTextField-root
    color: #75809E
    background: #EAECF3
    padding: 2.5px

    &:disabled
      border: none !important
      background: orange !important
      color: #898989 !important

    &:hover:not(:disabled)
      border: 1px solid #D4D8E1
      padding: 1.5px

    &:active:not(:disabled)
      background: white
      border: 1px solid #75809E !important

input::placeholder
  color: #75809E

input:disabled::placeholder
  color: #898989

.commonTextfield
  & > input
    ::placeholder
      color: #75809E

    &:disabled
      border: none !important
      background: #E0E0E0 !important
      color: #898989 !important

    &:hover:not(:disabled)
      padding: 19px !important
      border: 1px solid #D4D8E1 !important
      color: #0E1F4D !important

    &:focus-visible:not(:disabled)
      padding: 19px !important
      background: white !important
      border: 1px solid #75809E !important
      color: #0E1F4D !important

    &:active:not(:disabled)
      border: 1px solid #75809E !important
      color: #0E1F4D !important
