img.logo
  width: auto
  height: 40px
  margin: 30px auto

body
  display: flex
  flex-direction: column

  /* Pages */

  *[class$="AuthPage"]
    margin: 0 auto !important
    padding: 30px 0 !important
    max-width: 600px !important
    width: calc(100% - 20px - 20px) !important

    &._vc
      margin: auto !important

