@import "styles/mixins"

.registerPage
  display: flex
  flex-direction: column
  align-items: center
  max-width: 600px !important

  // Steps
  .steps
    display: flex
    align-items: center
    margin-top: 50px
    gap: 20px

    .step
      display: flex
      align-items: center
      opacity: .3

      &._active
        opacity: 1

      .digit
        display: flex
        justify-content: center
        align-items: center
        width: 50px
        height: 50px
        border: 5px solid #aaa
        border-radius: 50%
        font-size: 21pt

        @at-root #{selector-replace(&, '.step', '.step._active')}
          border-color: #33B5FF

      .text
        margin-left: 10px
        font-weight: bold

  // FOrm
  form
    width: 100%
    flex-shrink: 0
