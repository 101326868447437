button.new
  padding: 0px
  border: none
  background: none
  box-shadow: none
  display: flex
  align-items: center
  justify-content: center

  &.superSmallIconed

    width: 16px
    height: 16px

  &.smallIconed
    width: 25px
    height: 25px

  &.mediumIconed
    width: 35px
    height: 35px
    background: #33B5FF
    border-radius: 10px

  &.bigIconed
    display: flex
    align-items: center
    justify-content: center
    width: 45px
    height: 45px
    background: #EAECF3
    border-radius: 10px

  &.underlined
    font-style: normal
    font-weight: 700
    font-size: 15px
    line-height: 22px
    text-decoration-line: underline
    color: #FFFFFF

  &.withTitle
    background: #33B5FF
    border-radius: 10px
    padding: 9px 18px

    &.small
      padding: 0px 9px

    font-style: normal
    font-weight: 600
    font-size: 17px
    line-height: 27px
    color: #FFFFFF
    flex: none
    order: 0
    flex-grow: 0

    &:hover
      background: #1FA3EE

    &:disabled
      background: #E0E0E0
      color: #898989

    &.secondary
      background: #EAECF3
      color: #0E1F4D

      &:hover
        background: #EAECF3

  &.tip
    height: 45px !important
    background: #FDEAEB
    border: 1px solid #F2F2F2
    border-radius: 10px
    padding: 9px 15px

    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 20px
    text-decoration-line: underline
    color: #0E1F4D

    flex: none
    order: 0
    flex-grow: 0

  &._wa
    width: 100%
    flex: 1

.newHR
  background: #EAECF3
  margin: 15px auto

.common
  gap: 0
  @media(min-width: 760px)
    display: flex

    .commonSubtitle
        width: 40%

.commonH1
  font-style: normal
  font-weight: 700
  font-size: 22px
  line-height: 30px
  color: #0E1F4D

.commonH2
  font-style: normal
  font-weight: 700
  font-size: 17px
  line-height: 27px
  color: #0E1F4D

.commonTitle
  font-style: normal
  font-weight: 700
  font-size: 21px
  line-height: 29px
  text-align: center
  color: #0E1F4D

.commonRegular15
  font-style: normal
  font-weight: 400
  font-size: 15px
  line-height: 22px
  color: #0E1F4D

.commonLabel
  font-style: normal
  font-weight: 600
  font-size: 17px
  line-height: 27px
  color: #0E1F4D

.hideOverflow
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap

.commonSubtitle
  font-style: normal
  font-weight: 400
  font-size: 15px
  line-height: 22px
  color: #75809E
  overflow-wrap: break-word
  hyphens: auto

.priorityHigh
  white-space: nowrap

svg
  &.smallIcon
    width: 16px
    height: 15px

  &.icon
    width: 25px !important
    height: 25px !important

    &.light
      fill: #D4D8E1

      &:hover
        fill: #75809E

    &.dark
      fill: #75809E

    &.white
      fill: #ffffff

img
  &.smallIcon
    width: 16px
    height: 15px

  &.icon
    width: 25px
    height: 25px
    fill: #75809E

    .light
      fill: red

div
  &.titleContainer
    display: flex
    align-items: center
    justify-content: space-between
    gap: 15px

    &.framed
      border: 1px solid #D4D8E1
      border-radius: 10px
      padding: 18px 15px 18px 15px

  &.buttonsContainer
    display: flex
    align-items: center
    gap: 10px

    &._wa
      width: 100%

    &.editContractKeywordContainer
      margin-top: 15px

    textarea
      font-style: normal
      font-weight: 400
      font-size: 15px
      line-height: 20px
      color: #0E1F4D

  &.centeredLabel
    display: flex
    align-items: center
    justify-content: center

.contractTitle

  font-style: normal
  font-weight: 700
  font-size: 26px
  line-height: 35px
  color: #0E1F4D

.navbar
  position: fixed
  width: 100%
  align-items: center
  z-index: 9
  background-color: #F9F9FB
  border-bottom: 1px solid #f1f1f1
  top: 0
  gap: 0
  padding: 0
  margin: 0
  left: 0

.navbarHeader
  font-style: normal
  font-weight: 400
  font-size: 15px
  line-height: 22px
  text-align: right
  color: #0E1F4D

.serviceMessage
  font-style: normal
  font-weight: 400
  font-size: 15px
  line-height: 22px
  text-align: right
  color: #0E1F4D
  opacity: 0.5

.sticky
  position: fixed
  top: 0
  width: 400px

  overflow: scroll
  overflow-x: hidden
  max-height: 97vh
  margin-top: 15px
  margin-bottom: 15px
  margin-right: 12px
  transition: .32s
  z-index: 10

.sticky > div
  padding-right: 4px

.sticky::-webkit-scrollbar
  box-sizing: border-box
  padding-left: 12px
  width: 4px

.sticky::-webkit-scrollbar-track
  background: #D1D9E0
  border-radius: 2px

.sticky::-webkit-scrollbar-thumb
  background: #8E94B1
  border-radius: 2px

.errorContainer
  padding: 10px 10px 15px 15px
  margin-top: 15px
  background: #FDEAEB
  border-radius: 15px

.errorMessage
  font-style: normal
  font-weight: 600
  font-size: 13px
  line-height: 20px
  color: #E22631

.scrollView
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  gap: 10px

  margin-top: auto
  position: fixed

  background: #0E1F4D

  flex-wrap: nowrap

  height: 40px
  width: 100%
  bottom: 0px
  left: 0
  right: 0

  padding: 10px 12px

  z-index: 3

  font-style: normal
  font-weight: 600
  font-size: 13px
  line-height: 20px
  color: white

textarea::placeholder
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap
  color: #75809E

textarea:active
  color: white
  border: 1px solid #D4D8E1

div.sign
  border: none
  background: none
  box-shadow: none
  display: flex
  align-items: center
  justify-content: center
  border-radius: 10px
  background: #33B5FF

button.sign
  font-style: normal
  font-weight: 600
  font-size: 17px
  line-height: 27px
  color: #FFFFFF
  background: #33B5FF
  flex: none
  order: 0
  flex-grow: 0

  &:hover
    background: #1FA3EE

  &.left
    border-radius: 10px 0 0 10px
    padding: 9px 11px 9px 18px

  &.right
    border-radius: 0 10px 10px 0
    padding: 9px 11px 9px 11px