.editContractEntitiesContainer
  background: #EAECF3
  border-radius: 20px
  padding: 15px
  @media(max-width: 1090px)
    max-width: 400px

  @media(max-width: 760px)
    max-width: 100%

  &.topMargin
    margin-top: 15px

.editContractEntitiesTitle
  font-style: normal
  font-weight: 700
  font-size: 21px
  line-height: 29px
  color: #0E1F4D

.editContractEntitiesTip
  margin-top: 12px
  font-style: normal
  font-weight: 400
  font-size: 15px
  line-height: 22px
  color: #75809E

.editContractEntitiesCloseTip
  font-style: normal
  font-weight: 400
  font-size: 15px
  line-height: 22px
  color: #0E1F4D

.editContractEntityContainer
  margin-top: 15px
  background: #FFFFFF
  border: 1px solid #F2F2F2
  box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.05)
  border-radius: 20px
  padding: 20px

.editContractTitle
  font-style: normal
  font-weight: 700
  font-size: 17px
  line-height: 27px
  color: #0E1F4D

.editContractKeywordLabel
  font-style: normal
  font-weight: 400
  font-size: 17px
  line-height: 27px
  color: #0E1F4D

.editContractDescriptionLabel
  margin-top: 15px
  font-style: normal
  font-weight: 400
  font-size: 15px
  line-height: 20px
  color: #75809E
  white-space: pre-wrap

.editContractInputContainer
  margin-top: 15px

.sectionsContainer
  display: flex
  flex-direction: column
  gap: 10px

.settingsSection
  border-radius: 20px
  transition: background .6s

  &.closed
    background: #EAECF3
    padding: 15px

    &.second
      background: #F1F2F7

  &.expanded
    background: white
    padding: 14px
    border-color: #D4D8E1
    border-width: 1px
    border-style: solid

    &.second
      background: white
      border-color: #75809E

.photo-container img 
  max-width: 300px !important
  max-height: 300px !important
  width: auto !important
  height: auto !important
  margin: 0 !important
  display: block !important
  border-radius: 10px !important
  object-fit: contain !important


.photo-container div 
  max-width: 100% !important
  margin: 0 !important
  padding: 0 !important

