@import "styles/mixins"

.profilePage

  @media(max-width: 760px)
    margin: 0px auto !important
    padding-left: 0px !important
    padding-right: 0px !important

  .container
    padding: 20px
    box-shadow: none
    border-radius: 0

    @media(min-width: 760px)
      display: flex
      gap: 15px

    section
      width: 100%
      display: flex
      flex-direction: column
      gap: 15px

    aside
      flex-shrink: 0
      display: flex
      flex-direction: column
      gap: 15px

      @media(max-width: 760px)
        width: 100%
        margin-top: 15px

      @media(min-width: 760px)
        margin-top: 45px
        width: 340px

    .row-data
      display: flex
      margin-top: 10px
      user-select: text

    .tariffs
      display: flex
      flex-direction: column
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))
      grid-gap: 10px
      margin-top: 20px

      .tariff
        transition: .32s
        padding: 15px 10px
        box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.05)
        border-radius: 20px

        .name

          font-style: normal
          font-weight: 700
          font-size: 22px
          line-height: 30px
          color: #FFFFFF
          padding: 5px 15px
          border-radius: 20px

        .promoCodeBonus

          font-style: normal
          font-weight: 700
          font-size: 18px
          line-height: 25px
          color: #FFFFFF
          padding: 5px 15px
          border-radius: 20px

        .price
          margin-left: 15px

          font-style: normal
          font-weight: 700
          font-size: 15px
          line-height: 22px
          color: #FFFFFF


      @media(max-width: 760px)
        width: 100%

      .help-links
        margin-top: 20px

        a
          display: flex
          align-items: center
          gap: 10px
          margin: 10px 0
          text-decoration: none
          @include clear-margins

          svg
            width: 24px

          span
            display: block

  .switch
    position: relative
    display: inline-block
    width: 40px
    height: 20px
    
    &.disabled
      opacity: 0.5
      pointer-events: none
      
    input
      opacity: 0
      width: 0
      height: 0
    
    .slider
      position: absolute
      cursor: pointer
      top: 0
      left: 0
      right: 0
      bottom: 0
      background-color: #ccc
      transition: .4s
      
      &:before
        position: absolute
        content: ""
        height: 16px
        width: 16px
        left: 2px
        bottom: 2px
        background-color: white
        transition: .4s
      
      &.round
        border-radius: 20px
        
        &:before
          border-radius: 50%
    
    input:checked + .slider
      background-color: #1FA3EE
      
      &:before
        transform: translateX(20px)


